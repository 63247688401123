var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entity-view-overview-wrapper flex m-display-block fullwidth"},[_c('div',{staticClass:"col-6 pa-4"},[_c('div',{staticClass:"fs-16 bold-600 mb-4 entity-view__big-header"},[_vm._v(" Summary ")]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Entity Name ")]),(_vm.formData.name && _vm.formData.name.value)?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.name.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Legal Name ")]),(
          _vm.formData.data &&
            _vm.formData.data.legalName &&
            _vm.formData.data.legalName.value
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.legalName.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Parent company / ownership ")]),(
          _vm.formData.data &&
            _vm.formData.data.parentCompany &&
            _vm.formData.data.parentCompany.value
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.parentCompany.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Entity website ")]),(
          _vm.formData.data &&
            _vm.formData.data.website &&
            _vm.formData.data.website.value &&
            _vm.formData.data.website.value.length
        )?_c('div',{staticClass:"entity-view-value"},_vm._l((_vm.formData.data.website.value),function(item,i){return _c('div',{key:i,staticClass:"mr-2 display-inline"},[_c('div',{staticClass:"display-inline"},[(_vm.isValidURL(item.link))?_c('div',{staticClass:"display-inline word-break"},[_c('a',{staticClass:"accent-text-link",attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.link))])]):_c('div',{staticClass:"display-inline word-break"},[_vm._v(" "+_vm._s(item.link || '–')+" ")]),(i !== _vm.formData.data.website.value.length - 1)?_c('span',[_vm._v(" , ")]):_vm._e()])])}),0):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Domiciled Country ")]),(
          _vm.formData.data &&
            _vm.formData.data.domicliedCountry &&
            _vm.formData.data.domicliedCountry.value
        )?_c('div',{staticClass:"entity-view-value"},[_c('div',{staticClass:"flex align-center"},[(_vm.formData.data.domicliedCountry.icon)?_c('GlImageViewer',{staticClass:"mr-1 flag-icon-small",attrs:{"src":_vm.formData.data.domicliedCountry.icon}}):_vm._e(),_vm._v(_vm._s(_vm.formData.data.domicliedCountry.value)+" ")],1)]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Status ")]),(
          _vm.formData.data && _vm.formData.data.status && _vm.formData.data.status.value
        )?_c('div',{staticClass:"entity-view-value bold-600",style:({ color: _vm.formData.data.status.color })},[_vm._v(" "+_vm._s(_vm.formData.data.status.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Type ")]),(
          _vm.formData &&
            _vm.formData.data &&
            _vm.formData.data.type &&
            _vm.formData.data.type.value
        )?_c('div',{staticClass:"entity-view-value"},[_c('GlTag',{staticClass:"mr-1 mb-1",attrs:{"score":_vm.formData.data.type.value.score,"tag":_vm.formData.data.type.value.name}})],1):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block max-w-400 overflow-auto"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Associated tags ")]),(
          _vm.formData &&
            _vm.formData.data &&
            _vm.formData.data.tags &&
            _vm.formData.data.tags.value &&
            _vm.formData.data.tags.value.length
        )?_c('div',{staticClass:"entity-view-value"},_vm._l((_vm.formData.data.tags.value),function(tag,index){return _c('GlTag',{key:index,staticClass:"mr-1 mb-1",attrs:{"score":tag.score,"tag":tag.name}})}),1):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Description ")]),(
          _vm.formData.data &&
            _vm.formData.data.description &&
            _vm.formData.data.description.value
        )?_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_c('GlEditor',{staticClass:"view-entity-quill-editor",attrs:{"disabled":"","value":_vm.formData.data.description.value}})],1):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Screenshots ")]),(_vm.loadingScreenshoots)?_c('VueSkeletonLoader',{attrs:{"animation":"wave","color":'#bec3d8',"height":120,"radius":'2',"type":"rect"}}):_c('div',[(
            _vm.formData.data &&
              _vm.formData.data.screenshoots &&
              _vm.formData.data.screenshoots.value &&
              _vm.formData.data.screenshoots.value.length
          )?_c('div',{staticClass:"entity-screensot-wrapper"},_vm._l((_vm.formData.data.screenshoots.value),function(item,i){return _c('div',{key:i,staticClass:"entity-screensot",on:{"click":function($event){_vm.$emit('showViewImageModal')
              _vm.$emit('setImageModalData', _vm.formData.data.screenshoots.value, i)}}},[(item.icon && item.icon.includes('data:application/pdf;'))?_c('div',[_c('embed',{staticClass:"pt-4",attrs:{"src":item.icon,"type":"application/pdf"}}),_c('div',{staticClass:"entity-screensot-full-view-icon link-text"},[_vm._v(" Full view ")])]):_c('GlImageViewer',{attrs:{"src":item.icon}})],1)}),0):_c('div',[_vm._v(" – ")])])],1)]),_c('div',{staticClass:"col-6 pa-4"},[_c('div',{staticClass:"fs-16 bold-600 mb-4 entity-view__big-header"},[_vm._v(" Entity Details ")]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" KYC ")]),(
          _vm.formData.data && _vm.formData.data.isKYC && _vm.formData.data.isKYC.value
        )?_c('div',{staticClass:"entity-view-value entity-view-value--17 bold-600",style:({ color: _vm.formData.data.isKYC.color })},[_vm._v(" "+_vm._s(_vm.formData.data.isKYC.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Provided Services ")]),(
          _vm.formData.data &&
            _vm.formData.data.providedServices &&
            _vm.formData.data.providedServices.value
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.providedServices.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Primary operational regions ")]),(
          _vm.formData.data &&
            _vm.formData.data.primaryOperationalRegions &&
            _vm.formData.data.primaryOperationalRegions.value &&
            _vm.formData.data.primaryOperationalRegions.value.length
        )?_c('div',{staticClass:"entity-view-value entity-view-value--flag flex flex-wrap"},[_vm._l((_vm.formData.data.primaryOperationalRegions.value),function(item,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(ind <= _vm.showItemsLenght || _vm.showFullRegions),expression:"ind <= showItemsLenght || showFullRegions"}],key:item.name,staticClass:"flex align-center mr-2"},[(item.icon)?_c('GlImageViewer',{staticClass:"mr-1 flag-icon-small",attrs:{"src":item.icon}}):_vm._e(),(item.link && _vm.isValidURL(item.link))?_c('div',{staticClass:"display-inline word-break"},[_c('a',{staticClass:"accent-text-link",attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.name))])]):_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),(
              ind !== _vm.formData.data.primaryOperationalRegions.value.length - 1
            )?_c('span',[_vm._v(",")]):_vm._e()],1)}),(
            _vm.formData.data.primaryOperationalRegions.value.length - 1 >
              _vm.showItemsLenght && !_vm.showFullRegions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullRegions = true}}},[_vm._v(" Show more... ")]):_vm._e(),(
            _vm.formData.data.primaryOperationalRegions.value.length - 1 >
              _vm.showItemsLenght && _vm.showFullRegions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullRegions = false}}},[_vm._v(" Show less ")]):_vm._e()],2):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Restricted Jurisdictions ")]),(
          _vm.formData.data &&
            _vm.formData.data.restrictedJurisdictions &&
            _vm.formData.data.restrictedJurisdictions.value &&
            _vm.formData.data.restrictedJurisdictions.value.length
        )?_c('div',{staticClass:"entity-view-value entity-view-value--flag flex flex-wrap"},[_vm._l((_vm.formData.data.restrictedJurisdictions.value),function(item,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(ind <= _vm.showItemsLenght || _vm.showFullJurisdictions),expression:"ind <= showItemsLenght || showFullJurisdictions"}],key:item.name,staticClass:"flex align-center mr-2"},[(item.icon)?_c('GlImageViewer',{staticClass:"mr-1 flag-icon-small",attrs:{"src":item.icon}}):_vm._e(),(item.link && _vm.isValidURL(item.link))?_c('div',{staticClass:"display-inline word-break"},[_c('a',{staticClass:"accent-text-link",attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.name))])]):_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),(
              ind !== _vm.formData.data.restrictedJurisdictions.value.length - 1
            )?_c('span',[_vm._v(",")]):_vm._e()],1)}),(
            _vm.formData.data.restrictedJurisdictions.value.length - 1 >
              _vm.showItemsLenght && !_vm.showFullJurisdictions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullJurisdictions = true}}},[_vm._v(" Show more... ")]):_vm._e(),(
            _vm.formData.data.restrictedJurisdictions.value.length - 1 >
              _vm.showItemsLenght && _vm.showFullJurisdictions
          )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullJurisdictions = false}}},[_vm._v(" Show less ")]):_vm._e()],2):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Fiat currency trading ")]),(
          _vm.formData.data &&
            _vm.formData.data.isFiatCurrencyTrading &&
            _vm.formData.data.isFiatCurrencyTrading.value
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.isFiatCurrencyTrading.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Office addresses ")]),(
          _vm.formData.data &&
            _vm.formData.data.officeAddress &&
            _vm.formData.data.officeAddress.value
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.officeAddress.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Languages ")]),(
          _vm.formData.data &&
            _vm.formData.data.languages &&
            _vm.formData.data.languages.value &&
            _vm.formData.data.languages.value.length
        )?_c('div',[(_vm.formData.data.languages.value.length)?_c('div',{staticClass:"entity-view-value flex flex-wrap"},[_vm._l((_vm.formData.data.languages.value),function(item,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(ind <= _vm.showItemsLenght || _vm.showFullLanguage),expression:"ind <= showItemsLenght || showFullLanguage"}],key:item.name,staticClass:"flex align-baseline mr-2"},[_vm._v(" "+_vm._s(item.name)+" "),(ind !== _vm.formData.data.languages.value.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),(
              _vm.formData.data.languages.value.length - 1 > _vm.showItemsLenght &&
                !_vm.showFullLanguage
            )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullLanguage = true}}},[_vm._v(" Show more... ")]):_vm._e(),(
              _vm.formData.data.languages.value.length - 1 > _vm.showItemsLenght &&
                _vm.showFullLanguage
            )?_c('div',{staticClass:"accent-text-link pointer",on:{"click":function($event){_vm.showFullLanguage = false}}},[_vm._v(" Show less ")]):_vm._e()],2):_vm._e()]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column mb-4 entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Privacy coins supported flag ")]),(
          _vm.formData.data &&
            _vm.formData.data.isPrivacyCoinsSupported &&
            _vm.formData.data.isPrivacyCoinsSupported.value
        )?_c('div',{staticClass:"entity-view-value"},[_vm._v(" "+_vm._s(_vm.formData.data.isPrivacyCoinsSupported.value)+" ")]):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])]),_c('div',{staticClass:"flex column entity-view__small-block"},[_c('div',{staticClass:"label mb-2 entity-view__small-header"},[_vm._v(" Social Network link ")]),(
          _vm.formData.data &&
            _vm.formData.data.socialNetworkLinks &&
            _vm.formData.data.socialNetworkLinks.value &&
            _vm.formData.data.socialNetworkLinks.value.length
        )?_c('div',{staticClass:"entity-view-value entity-view-value--social flex flex-wrap"},_vm._l((_vm.formData.data.socialNetworkLinks.value),function(item,i){return _c('div',{key:i,staticClass:"flex align-baseline"},[(item.link && !_vm.isValidURL(item.link) && item.key === 'Mail')?_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.goMailto(item.link)}}},[(_vm.getValueByKey(_vm.socialIconsList, item.key, 'key'))?_c('GlImageViewer',{staticClass:"icon-small",attrs:{"src":_vm.getValueByKey(_vm.socialIconsList, item.key, 'key')}}):_vm._e()],1):(item.link && _vm.isValidURL(item.link))?_c('a',{attrs:{"href":item.link,"target":"_blank"}},[(_vm.getValueByKey(_vm.socialIconsList, item.key, 'key'))?_c('GlImageViewer',{staticClass:"icon-small",attrs:{"src":_vm.getValueByKey(_vm.socialIconsList, item.key, 'key')}}):_c('gl-icon',{staticClass:"mt-1",attrs:{"height":24,"link":"","name":"open","width":26}})],1):_c('div',[_vm._v(" "+_vm._s(item.link || '–')+" ")])])}),0):_c('div',{staticClass:"entity-view-value"},[_vm._v(" – ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }