<template>
  <div class="entity-view-overview-wrapper flex m-display-block fullwidth">
    <div class="col-6 pa-4">
      <div class="fs-16 bold-600 mb-4 entity-view__big-header">
        Summary
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Entity Name
        </div>
        <div
          v-if="formData.name && formData.name.value"
          class="entity-view-value"
        >
          {{ formData.name.value }}
        </div>
        <div
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Legal Name
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.legalName &&
              formData.data.legalName.value
          "
          class="entity-view-value"
        >
          {{ formData.data.legalName.value }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Parent company / ownership
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.parentCompany &&
              formData.data.parentCompany.value
          "
          class="entity-view-value"
        >
          {{ formData.data.parentCompany.value }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Entity website
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.website &&
              formData.data.website.value &&
              formData.data.website.value.length
          "
          class="entity-view-value"
        >
          <div
            v-for="(item, i) in formData.data.website.value"
            :key="i"
            class="mr-2 display-inline"
          >
            <div class="display-inline">
              <div
                v-if="isValidURL(item.link)"
                class="display-inline word-break"
              >
                <a
                  class="accent-text-link"
                  :href="item.link"
                  target="_blank"
                >{{
                  item.link
                }}</a>
              </div>
              <div
                v-else
                class="display-inline word-break"
              >
                {{ item.link || '–' }}
              </div>
              <span v-if="i !== formData.data.website.value.length - 1">
                ,
              </span>
            </div>
          </div>
        </div>
        <div 
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Domiciled Country
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.domicliedCountry &&
              formData.data.domicliedCountry.value
          "
          class="entity-view-value"
        >
          <div class="flex align-center">
            <GlImageViewer
              v-if="formData.data.domicliedCountry.icon"
              class="mr-1 flag-icon-small"
              :src="formData.data.domicliedCountry.icon"
            />{{ formData.data.domicliedCountry.value }}
          </div>
        </div>
        <div 
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Status
        </div>
        <div
          v-if="
            formData.data && formData.data.status && formData.data.status.value
          "
          class="entity-view-value bold-600"
          :style="{ color: formData.data.status.color }"
        >
          {{ formData.data.status.value }}
        </div>
        <div 
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Type
        </div>
        <div
          v-if="
            formData &&
              formData.data &&
              formData.data.type &&
              formData.data.type.value
          "
          class="entity-view-value"
        >
          <GlTag
            class="mr-1 mb-1"
            :score="formData.data.type.value.score"
            :tag="formData.data.type.value.name"
          />
        </div>
        <div 
          v-else
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block max-w-400 overflow-auto">
        <div class="label mb-2 entity-view__small-header">
          Associated tags
        </div>
        <div
          v-if="
            formData &&
              formData.data &&
              formData.data.tags &&
              formData.data.tags.value &&
              formData.data.tags.value.length
          "
          class="entity-view-value"
        >
          <GlTag
            v-for="(tag, index) in formData.data.tags.value"
            :key="index"
            class="mr-1 mb-1"
            :score="tag.score"
            :tag="tag.name"
          />
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Description
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.description &&
              formData.data.description.value
          "
          style="white-space: pre-wrap"
        >
          <GlEditor
            class="view-entity-quill-editor"
            disabled
            :value="formData.data.description.value"
          />
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Screenshots
        </div>
        <VueSkeletonLoader
          v-if="loadingScreenshoots"
          animation="wave"
          :color="'#bec3d8'"
          :height="120"
          :radius="'2'"
          type="rect"
        />
        <div v-else>
          <div
            v-if="
              formData.data &&
                formData.data.screenshoots &&
                formData.data.screenshoots.value &&
                formData.data.screenshoots.value.length
            "
            class="entity-screensot-wrapper"
          >
            <div
              v-for="(item, i) in formData.data.screenshoots.value"
              :key="i"
              class="entity-screensot"
              @click="
                $emit('showViewImageModal')
                $emit('setImageModalData', formData.data.screenshoots.value, i)
              "
            >
              <div
                v-if="item.icon && item.icon.includes('data:application/pdf;')"
              >
                <embed
                  class="pt-4"
                  :src="item.icon"
                  type="application/pdf"
                >
                <div class="entity-screensot-full-view-icon link-text">
                  Full view
                </div>
              </div>
              <GlImageViewer
                v-else
                :src="item.icon"
              />
            </div>
          </div>
          <div v-else>
            –
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 pa-4">
      <div class="fs-16 bold-600 mb-4 entity-view__big-header">
        Entity Details
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          KYC
        </div>
        <div
          v-if="
            formData.data && formData.data.isKYC && formData.data.isKYC.value
          "
          class="entity-view-value entity-view-value--17 bold-600"
          :style="{ color: formData.data.isKYC.color }"
        >
          {{ formData.data.isKYC.value }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Provided Services
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.providedServices &&
              formData.data.providedServices.value
          "
          class="entity-view-value"
        >
          {{ formData.data.providedServices.value }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Primary operational regions
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.primaryOperationalRegions &&
              formData.data.primaryOperationalRegions.value &&
              formData.data.primaryOperationalRegions.value.length
          "
          class="entity-view-value entity-view-value--flag flex flex-wrap"
        >
          <div
            v-for="(item, ind) in formData.data.primaryOperationalRegions.value"
            v-show="ind <= showItemsLenght || showFullRegions"
            :key="item.name"
            class="flex align-center mr-2"
          >
            <GlImageViewer
              v-if="item.icon"
              class="mr-1 flag-icon-small"
              :src="item.icon"
            />
            <div
              v-if="item.link && isValidURL(item.link)"
              class="display-inline word-break"
            >
              <a
                class="accent-text-link"
                :href="item.link"
                target="_blank"
              >{{
                item.name
              }}</a>
            </div>
            <div v-else>
              {{ item.name }}
            </div>
            <span
              v-if="
                ind !== formData.data.primaryOperationalRegions.value.length - 1
              "
            >,</span>
          </div>
          <div
            v-if="
              formData.data.primaryOperationalRegions.value.length - 1 >
                showItemsLenght && !showFullRegions
            "
            class="accent-text-link pointer"
            @click="showFullRegions = true"
          >
            Show more...
          </div>
          <div
            v-if="
              formData.data.primaryOperationalRegions.value.length - 1 >
                showItemsLenght && showFullRegions
            "
            class="accent-text-link pointer"
            @click="showFullRegions = false"
          >
            Show less
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Restricted Jurisdictions
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.restrictedJurisdictions &&
              formData.data.restrictedJurisdictions.value &&
              formData.data.restrictedJurisdictions.value.length
          "
          class="entity-view-value entity-view-value--flag flex flex-wrap"
        >
          <div
            v-for="(item, ind) in formData.data.restrictedJurisdictions.value"
            v-show="ind <= showItemsLenght || showFullJurisdictions"
            :key="item.name"
            class="flex align-center mr-2"
          >
            <GlImageViewer
              v-if="item.icon"
              class="mr-1 flag-icon-small"
              :src="item.icon"
            />
            <div
              v-if="item.link && isValidURL(item.link)"
              class="display-inline word-break"
            >
              <a
                class="accent-text-link"
                :href="item.link"
                target="_blank"
              >{{
                item.name
              }}</a>
            </div>
            <div v-else>
              {{ item.name }}
            </div>
            <span
              v-if="
                ind !== formData.data.restrictedJurisdictions.value.length - 1
              "
            >,</span>
          </div>
          <div
            v-if="
              formData.data.restrictedJurisdictions.value.length - 1 >
                showItemsLenght && !showFullJurisdictions
            "
            class="accent-text-link pointer"
            @click="showFullJurisdictions = true"
          >
            Show more...
          </div>
          <div
            v-if="
              formData.data.restrictedJurisdictions.value.length - 1 >
                showItemsLenght && showFullJurisdictions
            "
            class="accent-text-link pointer"
            @click="showFullJurisdictions = false"
          >
            Show less
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Fiat currency trading
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.isFiatCurrencyTrading &&
              formData.data.isFiatCurrencyTrading.value
          "
          class="entity-view-value"
        >
          {{ formData.data.isFiatCurrencyTrading.value }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>
      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Office addresses
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.officeAddress &&
              formData.data.officeAddress.value
          "
          class="entity-view-value"
        >
          {{ formData.data.officeAddress.value }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Languages
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.languages &&
              formData.data.languages.value &&
              formData.data.languages.value.length
          "
        >
          <div
            v-if="formData.data.languages.value.length"
            class="entity-view-value flex flex-wrap"
          >
            <div
              v-for="(item, ind) in formData.data.languages.value"
              v-show="ind <= showItemsLenght || showFullLanguage"
              :key="item.name"
              class="flex align-baseline mr-2"
            >
              {{ item.name }}
              <span
                v-if="ind !== formData.data.languages.value.length - 1"
              >,</span>
            </div>
            <div
              v-if="
                formData.data.languages.value.length - 1 > showItemsLenght &&
                  !showFullLanguage
              "
              class="accent-text-link pointer"
              @click="showFullLanguage = true"
            >
              Show more...
            </div>
            <div
              v-if="
                formData.data.languages.value.length - 1 > showItemsLenght &&
                  showFullLanguage
              "
              class="accent-text-link pointer"
              @click="showFullLanguage = false"
            >
              Show less
            </div>
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column mb-4 entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Privacy coins supported flag
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.isPrivacyCoinsSupported &&
              formData.data.isPrivacyCoinsSupported.value
          "
          class="entity-view-value"
        >
          {{ formData.data.isPrivacyCoinsSupported.value }}
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>

      <div class="flex column entity-view__small-block">
        <div class="label mb-2 entity-view__small-header">
          Social Network link
        </div>
        <div
          v-if="
            formData.data &&
              formData.data.socialNetworkLinks &&
              formData.data.socialNetworkLinks.value &&
              formData.data.socialNetworkLinks.value.length
          "
          class="entity-view-value entity-view-value--social flex flex-wrap"
        >
          <div
            v-for="(item, i) in formData.data.socialNetworkLinks.value"
            :key="i"
            class="flex align-baseline"
          >
            <div
              v-if="item.link && !isValidURL(item.link) && item.key === 'Mail'"
              class="pointer"
              @click="goMailto(item.link)"
            >
              <GlImageViewer
                v-if="getValueByKey(socialIconsList, item.key, 'key')"
                class="icon-small"
                :src="getValueByKey(socialIconsList, item.key, 'key')"
              />
            </div>
            <a
              v-else-if="item.link && isValidURL(item.link)"
              :href="item.link"
              target="_blank"
            >
              <GlImageViewer
                v-if="getValueByKey(socialIconsList, item.key, 'key')"
                class="icon-small"
                :src="getValueByKey(socialIconsList, item.key, 'key')"
              />
              <gl-icon
                v-else
                class="mt-1"
                :height="24"
                link
                name="open"
                :width="26"
              /></a>
            <div v-else>
              {{ item.link || '–' }}
            </div>
          </div>
        </div>
        <div 
          v-else 
          class="entity-view-value"
        >
          –
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlImageViewer from '@/components/gl-image-viewer'
import GlTag from '@/components/gl-tag'
import GlIcon from '@/components/gl-icon'
import VueSkeletonLoader from 'skeleton-loader-vue'
import GlEditor from '@/components/gl-editor'
// Utils
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { isValidURL } from '@/utils/text-formatter'
import { getValueByKey } from '@/utils/helpers'
import { goMailto } from '@/utils/go-to-route'
//models
import { socialIconsList } from '@/pages/entity/models/options-list'

export default {
  name: 'EntityBanksTable',
  components: {
    VueSkeletonLoader,
    GlImageViewer,
    GlTag,
    GlIcon,
    GlEditor
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    loadingScreenshoots: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showItemsLenght: 9,
      showFullLanguage: false,
      showFullJurisdictions: false,
      showFullRegions: false,
    }
  },
  computed: {
    socialIconsList() {
      return socialIconsList
    },
  },
  watch: {
    $route: {
      handler() {
        this.showFullLanguage = false
        this.showFullJurisdictions = false
        this.showFullRegions = false
      },
    },
  },
  methods: {
    capitalizeFirstLetter,
    isValidURL,
    getValueByKey,
    goMailto,
  },
}
</script>

<style>
.entity-view-overview-wrapper > div {
  border-right: 1px solid var(--cotton-grey-f-5);
  height: max-content;
}
.entity-view-overview-wrapper > div:first-child {
  min-width: calc(50% + 4px);
}
.entity-view-overview-wrapper > div:first-child .entity-view-value {
  line-height: 17px;
}
.entity-view-overview-wrapper > div:first-child + div {
  min-width: calc(50% - 4px);
}
.view-entity-quill-editor #quill-container {
  height: unset;
}

.view-entity-quill-editor .ql-editor {
  padding: 0;
  line-height: 21px;
}

.view-entity-quill-editor .ql-toolbar.ql-snow {
  display: none;
}

.view-entity-quill-editor .ql-snow.ql-toolbar, .view-entity-quill-editor .ql-container.ql-snow {
  border: none;
}

.view-entity-quill-editor .ql-container {
  font-family: unset;
  font-size: 14px;
}

.entity-view-value {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: var(--space-cadet);
}
.entity-view-value--17 {
  line-height: 17px;
}
.entity-view-value--flag img {
  width: 14px;
}
.entity-view-value--social {
  gap: 16px;
}
.entity-view-value--social a {
  line-height: 1;
}
.entity-view-value--social img {
  width: 24px;
  height: 24px;
  max-height: 24px;
}
.entity-view__big-header {
  line-height: 19px;
}
.entity-view__small-block {
  line-height: 14px;
}
.entity-view__small-header {
  margin-bottom: 9px !important;
  line-height: 14px !important;
}
</style>
